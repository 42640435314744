.back-button {
  position: absolute;
  top: 47px;
  left: 47px;
  z-index: 100;
}
#portfolio {
    position: relative;
    background-color: white;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio {
      background-color: #1c1c1c;
    }
  } */
  #portfolio span {
    color: #4f4f4f;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio span {
      color: #ccc;
    }
  } */
  #portfolio h2 {
    color: #4f4f4f;
    margin: 0px;
    padding: 50px;
    display: flex;
    justify-content: center;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio h2 {
      color: white;
    }
  } */
  #portfolio .rc-slider {
    max-width: 400px;
  }
  @media screen and (orientation: portrait) {
    #portfolio .rc-slider {
      max-width: 70vw;
    }
  }
  #portfolio .rc-slider .rc-slider-track {
    background-color: #4aaeae;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .rc-slider .rc-slider-track {
      background-color: #e6e6e6;
    }
  } */
  #portfolio .rc-slider .rc-slider-handle,
  #portfolio .rc-slider .rc-slider-dot-active {
    border-color: #44a3a3;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .rc-slider .rc-slider-handle,
    #portfolio .rc-slider .rc-slider-dot-active {
      border-color: #fff;
    }
  } */
  #portfolio .rc-slider .rc-slider-handle:active,
  #portfolio .rc-slider .rc-slider-dot-active:active {
    box-shadow: 0 0 5px black;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .rc-slider .rc-slider-handle:active,
    #portfolio .rc-slider .rc-slider-dot-active:active {
      box-shadow: 0 0 5px #fff;
    }
  } */
  #portfolio .rc-slider .rc-slider-mark-text {
    width: 100px;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .rc-slider .rc-slider-rail {
      background-color: rgb(72, 72, 72);
    }
  } */
  #portfolio .portfolio-box {
    padding: 10% 10%;
    color: #5c5c5c;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .portfolio-box {
      color: #e6e6e6;
    }
  } */
  #portfolio .portfolio-box h1 {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2em;
    color: #4f4f4f;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .portfolio-box h1 {
      color: #fff;
    }
  } */
  #portfolio .portfolio-box ul {
    list-style: none;
    padding-left: 0;
  }
  #portfolio .portfolio-box li {
    font-size: 1.15em;
    transition: all 0.5s ease;
  }
  #portfolio .portfolio-box li:before {
    content: "> ";
  }
  #portfolio .portfolio-box .header {
    font-size: 1.2em;
  }
  #portfolio .portfolio-box .header .title {
    color: #5a5a5a;
    text-transform: uppercase;
    font-weight: bold;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .portfolio-box .header .title {
      color: #fff;
    }
  } */
  #portfolio .portfolio-box .header .desc {
    color: #5a5a5a;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .portfolio-box .header .desc {
      color: #fff;
    }
  } */
  #portfolio .portfolio-box .header .date {
    font-style: italic;
    color: #5c5c5c;
  }
  /* @media (prefers-color-scheme: dark) {
    #portfolio .portfolio-box .header .date {
      color: fade(#fff, 10%);
    }
  } */
  #portfolio .portfolio-box .divider-weak {
    color: #cfcfcf;
  }
  #portfolio .portfolio-box .divider-strong {
    color: #5a5a5a;
    font-weight: bold;
  }
  #portfolio .points-enter {
    opacity: 0.01;
  }
  #portfolio .points-enter.points-enter-active {
    opacity: 1;
    color: #4aaeae;
    transition: all 500ms ease;
  }
  #portfolio .points-exit {
    opacity: 1;
  }
  #portfolio .points-exit-active {
    opacity: 0.01;
    color: #5a5a5a;
    transition: all 500ms ease;
    text-decoration: line-through;
  }
  #portfolio .points-appear {
    opacity: 0.01;
  }
  #portfolio .points-appear.points-appear-active {
    opacity: 1;
    color: #4aaeae;
    transition: all 0.5s ease;
  }

  #portfolio .link {
    color: #5c5c5c;
    transition: all 500ms ease;
    text-decoration: none;
  }
  #portfolio .link:hover {
    transform: scale(1.05);
    color: #4aaeae;
  }